// import $ from "jquery";
import { Loader } from "@googlemaps/js-api-loader"
const mapStyle = require("./goole-map-style.js");

$(function () {
    let list_location = []
    let location, marker, map, prevMarker = ""
    let markers = []
    let el = $("#googlemap").get(0);
    let bounds;

    const loader = new Loader({
        apiKey: "AIzaSyAVjxnANd6GRHzpoC2ZL7ps91sl_q6JIeA",
        version: "weekly",
    });

    loader.load().then(() => {
        bounds = new google.maps.LatLngBounds()
        const map = new google.maps.Map(el, {
            styles: mapStyle.jomonMapStyle
        });

        const centerControlDiv = document.createElement("div");
        function CenterControl(controlDiv, map) {
            // Set CSS for the control border.
            const controlUI = document.createElement("div");
            controlUI.style.backgroundColor = "#fff";
            controlUI.style.border = "2px solid #fff";
            controlUI.style.borderRadius = "3px";
            controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
            controlUI.style.cursor = "pointer";
            controlUI.style.marginTop = "8px";
            controlUI.style.marginBottom = "22px";
            controlUI.style.textAlign = "center";
            controlUI.title = "Click to recenter the map";
            controlDiv.appendChild(controlUI);
            // Set CSS for the control interior.
            const controlText = document.createElement("div");
            controlText.style.color = "rgb(25,25,25)";
            controlText.style.fontFamily = "Roboto,Arial,sans-serif";
            controlText.style.fontSize = "13px";
            controlText.style.lineHeight = "38px";
            controlText.style.paddingLeft = "5px";
            controlText.style.paddingRight = "5px";
            controlText.innerHTML = "全体表示";
            controlUI.appendChild(controlText);
            // Setup the click event listeners: simply set the map to Chicago.
            controlUI.addEventListener("click", () => {
                map.fitBounds(bounds);
                map.setZoom(7.5);
                $(".infor").css('visibility', 'hidden');
                if (prevMarker) {
                    prevMarker.setIcon({
                        url: "",
                        scaledSize: new google.maps.Size(30, 32)
                    });
                    prevMarker = '';
                }
            });
        }

        CenterControl(centerControlDiv, map);
        map.controls[google.maps.ControlPosition.TOP_CENTER].push(centerControlDiv);
        setMarkers(map)
    }).catch(e => console.log(e))

    // Set Marker in the maps
    function setMarkers(map) {

        for (let i = 0; i < list_location.length; i++) {
            let area = list_location[i];
            marker = new google.maps.Marker({
                position: { lat: area.lat, lng: area.lng },
                map: map,
                animation: google.maps.Animation.DROP,
                icon: {
                    url: area.img_url,
                    scaledSize: new google.maps.Size(30, 32)
                },
                id: area.id
            });
            bounds.extend(marker.position);
            markers.push(marker)

            google.maps.event.addListener(marker, 'click', (function (marker) {
                return function () {
                    map.setZoom(8);
                    map.panTo(this.getPosition())
                    if (window.matchMedia("(max-width: 992px)").matches) {
                        $("body, html").animate({
                            scrollTop: $("#googlemap").offset().top - 64
                        }, 500)
                    }

                    if (prevMarker !== "") {
                        prevMarker.setIcon({
                            url: "",
                            scaledSize: new google.maps.Size(30, 32)
                        });
                    }
                    prevMarker = this;
                    this.setIcon({
                        url: "",
                        scaledSize: new google.maps.Size(60, 64)
                    });
                    getInforMarker(area)
                }
            })(marker, i));
        }
        map.fitBounds(bounds);

        // zoom-in a bit
        const handler = google.maps.event.addListener(map, "idle", function () {
            map.setZoom(7.5);
            google.maps.event.removeListener(handler);
        });

    }

    const getInforMarker = (area) => {
        let information = $(".infor");
        let title = ('assoc' === area.site_class) ? `${area.title} (関連資産)` : `${area.title}`;
        let template =
            `<dl>
                    <dt>${title} <small>${area.site_locality}</small></dt>
                    <dd>
                    ${area.description}
                    <a href="${area.url}"><span>Read more</span></a>
                    </dd>
                    <div class="close" > &times;</div>
                </dl>
                `;
        information.html(template)
        information.css('visibility', 'visible')
        $(".close").on('click', function () {
            $(this).closest(".infor").css('visibility', 'hidden')
        });
    }

    // Load all of information marker
    const getLocation = (el) => {
        let obj = {
            id: parseInt(el.data('id').split("-")[1]),
            title: el.data('title'),
            description: el.data('description'),
            url: el.data('url'),
            lat: el.data('lat'),
            lng: el.data('lng'),
            site_class: el.data('site-class'),
            site_locality: el.data('site-locality'),
            img_url: el.find('img').attr('src')
        }

        return obj;
    }

    $('a.gmap-marker').each(function (el) {
        location = getLocation($(this))
        list_location.push(location)

        // event click on location
        $(this).on('click', function (ev) {
            ev.preventDefault();
            location = getLocation($(this))
            var id = parseInt($(this).data('id').split("-")[1])
            for (let j = 0; j < markers.length; j++) {
                if (markers[j].id == id)
                    google.maps.event.trigger(markers[j], 'click');
            }
        });

    });
});